import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { isEmpty as _isEmpty, isEqual as _isEqual, head as _head } from 'lodash';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { GenericRegexp } from '../../regexp/generic.regexp';
import { NetPromoterScore } from '../../models/net-promoter-score';
import { NetPromoterScoreArrayResponse } from '../../interfaces/supplynet-core/net-promoter-score.interface';
import { NetPromoterScoreService } from '../../providers/net-promoter-score/net-promoter-score.service';

const DIALOG_BOTTOM_RIGHT_POSITION = 'bottom-right';
const ERROR_CODE_DUPLICATE = 409;
const ERROR_NET_PROMOTER_SCORE_STILL_VALID_MESSAGE = 'ERROR_NET_PROMOTER_SCORE_STILL_VALID';
const NET_PROMOTER_SCORE_COMMENTS_MAX_LENGTH = 300;
const NET_PROMOTER_SCORE_COMMENTS_MIN_LENGTH = 5;
const NET_PROMOTER_SCORE_LENGTH = 1;
const NET_PROMOTER_SCORE_MAX = 5;
const NET_PROMOTER_SCORE_MIN = 1;
const NO_COMMENTS = 'NO_COMMENTS';
const ONE_NUMBER = 1;
const SCORE_DISPLAY_PROPABILITY = environment.supplynetScoreDisplayProbability;

/**
 * @class
 * @description - Net Promoter Score component class.
 */
@Component({
  selector: 'net-promoter-score',
  templateUrl: './net-promoter-score.component.html',
  styleUrls: ['./net-promoter-score.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NetPromoterScoreComponent implements OnInit {
  public dialogPosition: string;
  public isBackgroundBlocked: boolean;
  public isNetPromoterScoreRegistered: boolean;
  public isRatingSectionActive: boolean;
  public isSaveButtonDisabled: boolean;
  public isScoredBefore: boolean;
  public isVisible: boolean;
  public netPromoterScore: NetPromoterScore;
  public netPromoterScoreForm: FormGroup;
  public scoreValue: number;
  public scoreValueRangeMax: number;
  public solutionId: string;
  public tenantId: string;
  public tenantName: string;
  public userId: string;

  /**
   * @description - Initializes required component services.
   * @param { AppService } _appService - Application service.
   * @param { FormBuilder } _formBuilder - Form builder service.
   * @param { NetPromoterScoreService } _netPromoterScoreService - Net promoter score service.
   */
  constructor(
    private _appService: AppService,
    private _formBuilder: FormBuilder,
    private _netPromoterScoreService: NetPromoterScoreService
  ) {}

  /**
   * @description - Initializes Angular lifecycle for component.
   */
  ngOnInit() {
    this.dialogPosition = DIALOG_BOTTOM_RIGHT_POSITION;
    this.isBackgroundBlocked = false;
    this.isNetPromoterScoreRegistered = false;
    this.isRatingSectionActive = true;
    this.isSaveButtonDisabled = false;
    this.isScoredBefore = false;
    this.isVisible = false;
    this.netPromoterScore = new NetPromoterScore();
    this.scoreValueRangeMax = NET_PROMOTER_SCORE_MAX;
    this.solutionId = environment.solutionId;
    this.tenantId = this._appService.getShipperOid();
    this.tenantName = this._appService.getUserInfoFromStorage().embarcador?.nombre;
    this.userId = this._appService.getUserOid();
    this.initForm();
    this.validateScoreDisplay();
  }

  /**
   * @description - Validates and reacts to whether or not the score is answered
   * on create and update actions.
   * @param { boolean } isAnsweredScore - Whether or not the score is answered to be saved.
   *
   * @return { void }
   */
  private checkAnsweredScore(isAnsweredScore: boolean): void {
    if (_isEqual(isAnsweredScore, false)) {
      this.onClickClose();
    } else {
      this.isRatingSectionActive = false;
    }
  }

  /**
   * @description - Validates obtained net promoter score error from create or update actions.
   * @param { any } error - Obtained net promoter score error.
   *
   * @return { void }
   */
  private checkError(error: any): void {
    if (error.status === ERROR_CODE_DUPLICATE || 
      error.error.message === ERROR_NET_PROMOTER_SCORE_STILL_VALID_MESSAGE) {
        this.isRatingSectionActive = false;
        this.isScoredBefore = true;
    } else {
      this.onClickClose();
    }
  }


  /**
   * @description - Saves user net promoter score.
   * @param { NetPromoterScore } netPromoterScore - Net promoter score to be created.
   * @param { boolean } isAnsweredScore - Whether or not the score is answered to be created.
   *
   * @return { void }
   */
  private createNetPromoterScore(netPromoterScore: NetPromoterScore, isAnsweredScore: boolean): void {
    this._netPromoterScoreService.createNetPromoterScore(netPromoterScore)
    .subscribe(() => {
      this.checkAnsweredScore(isAnsweredScore);
    },
    (error: any) => {
      this.checkError(error);
    });
  }

  /**
   * @description - Initializes the form 'netPromoterScoreForm'.
   * @return { void }
   */
  private initForm(): void {
    this.netPromoterScoreForm = this._formBuilder.group({
      score: new FormControl(null, [Validators.required, Validators.min(NET_PROMOTER_SCORE_MIN),
        Validators.max(NET_PROMOTER_SCORE_MAX), Validators.maxLength(NET_PROMOTER_SCORE_LENGTH), Validators.minLength(NET_PROMOTER_SCORE_LENGTH),
        Validators.pattern(GenericRegexp.NON_DECIMAL_ALLOWED)]),
      comments: new FormControl(null, [Validators.required,
        Validators.minLength(NET_PROMOTER_SCORE_COMMENTS_MIN_LENGTH), Validators.maxLength(NET_PROMOTER_SCORE_COMMENTS_MAX_LENGTH)])
    });
  }

  /**
   * @description - Updates user net promoter score.
   * @param { NetPromoterScore } netPromoterScore - Net promoter score to be created.
   * @param { boolean } isAnsweredScore - Whether or not the score is answered to be created.
   *
   * @return { void }
   */
  public updateNetPromoterScore(netPromoterScore: NetPromoterScore, isAnsweredScore: boolean): void {
    this._netPromoterScoreService.updateNetPromoterScore(netPromoterScore)
    .subscribe(() => {
      this.checkAnsweredScore(isAnsweredScore);
    },
    (error: any) => {
      this.checkError(error);
    });
  }

  /**
   * @description - Validates whether the net promoter score component
   * should be displayed or not.
   * @return { void }
   */
  private validateScoreDisplay(): void {
    this._netPromoterScoreService
      .getNetPromoterScoresByUserAndSolution(this.userId, this.tenantId, this.solutionId, true)
      .subscribe((result: NetPromoterScoreArrayResponse) => {
        if (!_isEmpty(result.item)) {
          this.netPromoterScore = _head(result.item);
          this.isNetPromoterScoreRegistered = true;
        }
        this.isVisible = Math.random() < Number(SCORE_DISPLAY_PROPABILITY);
      });
  }

  /**
   * @description - Resets score form and closes dialog.
   * @return { void }
   */
  public onClickClose(): void {
    this.isSaveButtonDisabled = true;
    this.isRatingSectionActive = false;
    this.scoreValue = null;
    this.netPromoterScoreForm.reset();
    this.isVisible = false
  }

  /**
   * @description - Sets user score.
   * @param { any } event - Rating event.
   *
   * @return { void }
   */
  public onClickRate(event: any): void {
    this.scoreValue = event.value;
    this.isBackgroundBlocked = true;
    this.netPromoterScoreForm.controls.score.setValue(this.scoreValue);
  }

  /**
   * @description - Reacts to save score event.
   * @param { boolean } isAnsweredScore - Whether or not the score is answered to be saved.
   *
   * @return { void }
   */
  public onClickSave(isAnsweredScore: boolean): void {
    this.isSaveButtonDisabled = true;

    this.netPromoterScore.attempts = this.netPromoterScore.attempts ? this.netPromoterScore.attempts + ONE_NUMBER : ONE_NUMBER;
    this.netPromoterScore.comments = isAnsweredScore ? this.netPromoterScoreForm.value.comments : NO_COMMENTS;
    this.netPromoterScore.creationUserId = this.userId;
    this.netPromoterScore.email = this._appService.getUserInfoFromStorage().embarcador?.correoElectronico;
    this.netPromoterScore.isAnswered = isAnsweredScore ?? false;
    this.netPromoterScore.scoreValue = isAnsweredScore ? this.netPromoterScoreForm.value.score : ONE_NUMBER;
    this.netPromoterScore.solutionId = this.solutionId;
    this.netPromoterScore.tenantId = this.tenantId;
    this.netPromoterScore.tenantName = this.tenantName;
    this.netPromoterScore.updateUserId = this.userId;

    if (!this.isRatingSectionActive) {
      return;
    }

    if (!this.isNetPromoterScoreRegistered)  {
      this.createNetPromoterScore(this.netPromoterScore, isAnsweredScore);
    } else {
      this.updateNetPromoterScore(this.netPromoterScore, isAnsweredScore);
    }
  }
}
