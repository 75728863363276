export const LanguageConstants = {
  ACCOUNT_CREATE_LABELS: 'accountCreateLabels',
  ACCOUNT_VIEW_LABELS: 'accountViewLabels',
  BILLING_SHEET_LABELS: 'billingSheetLabels',
  BILLING_SHEET_PDF_LABELS: 'billingSheetPdfLabels',
  CARRIER_CREATE: 'carrierCreateLabels',
  CARRIER_GROUP_CREATE_LABELS: 'carrierGroupCreateLabels',
  CARRIER_GROUP_CREATE_TAGS: 'carrierGroupCreateTags',
  CARRIER_GROUP_VIEW_LABELS: 'carrierGroupViewLabels',
  CARRIER_GROUP_VIEW_TAGS: 'carrierGroupViewTags',
  CARRIER_VIEW: 'carrierViewLabels',
  CHARGE_VALIDATION_LABELS: 'chargeValidationLabels',
  CONFIRM_DELIVERY_LABELS: 'ConfirmDeliveryStatusLabels',
  COST_REVIEW_LABELS: 'costReviewLabels',
  COST_STATUS_LABELS: 'costStatusLabels',
  CUSTOMER_INVOICE_DETAIL_LABELS: 'customerInvoiceDetailLabels',
  CUSTOMER_INVOICE_LABELS: 'customerInvoiceLabels',
  DETAIL_STOP_LABELS: 'detailStopLabels',
  DIALOG_ADDITIONAL_CHARGE_LABELS: 'dialogAdditionalChargesLabels',
  DIALOG_ASSIGN_CARRIERS_LABELS: 'dialogAssignCarriersLabels',
  DIALOG_BULK_LOAD_ERRORS_LABELS: 'dialogBulkLoadErrorsLabels',
  DIALOG_CREATE_BUL_LOAD_CONFIG_LABELS: 'dialogCreateBulkLoadConfigLabels',
  DIALOG_DYNAMIC_LOCATION_MAP_TAGS: 'dynamicLocationMapTags',
  DIALOG_FILES_DETAIL_LABELS: 'dialogFilesDetailLabels',
  DIALOG_FILE_PREVIEW: 'dialogFilePreviewTags',
  DIALOG_ORDER_BULK_LOAD_CONFIG: 'dialogOrderBulkLoadLabels',
  DIALOG_ORDER_DETAIL_LABELS: 'dialogOrderDetailLabels',
  DIALOG_PASSWORD_LABELS: 'dialogPasswordLabels',
  DIALOG_SCANNED_GUIDES_LABELS: 'dialogScannedGuidesLabels',
  DIALOG_TRANSPORT_ASSIGNMENT_LABELS: 'dialogTransportAssignmentLabels',
  DIALOG_VALIDATE_EVIDENCE_LABELS: 'DialogValidateEvidenceLabels',
  DOCUMENTARY_REGISTER_VIEW: 'documentaryRegisterView',
  DOWNLOAD_FILES_SERVICE_LABELS: 'downloadFilesServiceLabels',
  DRAG_AND_DROP_LABELS: 'dragAndDropLabels',
  DRIVER_CREATE_LABELS: 'driverCreateLabels',
  DRIVER_VIEW_LABELS: 'driverViewLabels',
  DRIVERAPPSTATUS: 'driverAppStatus',
  DYNAMIC_LOCATION_MAP: 'dynamicLocationMapLabels',
  EVIDENCE_CONTROL_VIEW: 'evidenceControlViewLabels',
  EVIDENCE_DOWNLOAD_LABELS: 'evidenceDownloadLabels',
  EVIDENCE_FOLIO_FORMAT_LABEL: 'evidencenceFolioFormatViewLabel',
  EVIDENCE_INFO_LABELS: 'evidenceInfoLabels',
  EVIDENCE_REGISTER_LABELS: 'evidenceRegisterViewLabels',
  EVIDENCE_MASSIVE_LOAD: 'evidenceMassiveLoad',
  EVIDENCE_RELEASE_FOLIO_DOCUMENT_LABEL: 'evidenceReleaseFolioDocumentLabel',
  EVIDENCE_RELEASE_LABELS: 'evidenceReleaseLabels',
  EVIDENCE_RELEASE_VIEW_LABELS: 'ordersReleaseViewLabels',
  EVIDENCE_STATUS_LABELS: 'evidenceStatusLabels',
  FILTER_MENU_SHIPMENT_STATUS: 'shipmentStatusFilter',
  FILTER_MENU_TYPES_SEARCH: 'filterMenuTypes',
  FILTER_MENU_TYPES_STATUS: 'filterMenuTypesStatus',
  FILTER_MENU_TYPES: 'filterMenuTypesLabels',
  GENERIC_INCIDENCE: 'genericIncidenceTags',
  GENERIC_TAGS: 'genericTags',
  GOODS_RECEIPT_LABELS: 'goodsReceiptLabels',
  GUIDE_HISTORY_TAGS: 'guideHistoryTags',
  IMPORT_SHIPMENTS_LABELS: 'importShipmentsLabels',
  INCIDENCE_EVIDENCE_CREATE_LABELS: 'incidenceEvidenceCreateLabels',
  INCIDENCE_EVIDENCE_CREATE_TAGS: 'incidenceEvidenceCreateTags',
  INCIDENCE_EVIDENCE_VIEW_LABELS: 'incidenceEvidenceViewLabels',
  INCIDENCE_EVIDENCE_VIEW_TAGS: 'incidenceEvidenceViewTags',
  INVOICE_RECORD_LABELS: 'invoiceRecordLabels',
  ITEMS_LIST_LABELS: 'itemsListLabels',
  JOURNEY_MENU_ITEM_COMPONENT: 'journeyMenuComponentItemLabels',
  JOURNEY_MENU: 'journeyMenuLabels',
  LANGUAGE_LABELS: 'languageLabels',
  LIVE_TRACKING_BAR_LABELS: 'liveTrackingBarLabels',
  LIVE_TRACKING_FILTER_LABELS: 'liveTrackingFilterLabels',
  LIVE_TRACKING_LABELS: 'liveTrackingLabels',
  LOAD_PLAN_LABELS: 'loadPlanTags',
  LOAD_NOT_EXECUTED_LABELS: 'loadNotExecutedLabels',
  LOCATION_CREATE_LABELS: 'locationCreateLabels',
  LOCATION_CREATE_TAGS: 'locationCreateTags',
  LOCATION_VIEW_LABELS: 'locationViewLabels',
  LOGIN_LABELS: 'loginLabels',
  MANUAL_DELIVERY_DETAIL_LABELS: 'manualDeliveryDetailLabels',
  MANUAL_DELIVERY_INFO_STATUS_CATALOG: 'manualDeliveryInformationStatus',
  MANUAL_DELIVERY_LABELS: 'manualDeliveryLabels',
  MANUAL_DELIVERY_REJECTION_RESPONSIBLE_LABELS: 'manualDeliveryRejectionResponsibleLabels',
  MANUAL_DELIVERY_REJECTION_RESPONSIBLE: 'manualDeliveryRejectionResponsible',
  MANUAL_DELIVERY_STATUS_CATALOG: 'manualDeliveryStatusCatalog',
  MAP_LABELS: 'mapTrackingLabels',
  MENU_LABELS: 'menuLabels',
  MINI_CAROUSEL: 'miniCarouselTags',
  NEW_EVIDENCE_STATUS: 'newEvidenceStatusLabels',
  MULTIPLE_INPUT_SEARCH_LABELS: 'multipleInputSearchLabels',
  ORDER_BULK_LOAD: 'orderBulkLoadLabels',
  ORDER_BULK_LOAD_ERRORS: 'orderBulkLoadErrors',
  ORDER_CREATE: 'orderCreateLabels',
  ORDER_HISTORY_LITE_LABELS: 'orderHistoryLiteLabels',
  ORDER_HISTORY_LITE_STATUS: 'orderHistoryLiteShipmentStatus',
  ORDER_HISTORY_LITE_TAGS: 'orderHistoryLiteTags',
  ORDER_MASSIVE_LOAD_LABELS: 'orderMassiveLoadLabels',
  ORDER_REMOVE_LABELS: 'orderRemoveDialogLabels',
  ORDER_SCANNED_STATUS_LABELS: 'orderScannedStatusLabels',
  ORDER_SEARCH_TYPES_LABELS: 'orderSearchTypesLabels',
  ORDER_SPLIT_VIEW: 'orderSplitView',
  ORDER_STATUS_CATALOG: 'orderStatusCatalog',
  ORDER_STATUS_LABELS: 'orderStatusLabels',
  ORDER_VIEW: 'viewOrderLabels',
  ORDER_VIEW_TAGS: 'viewOrderTags',
  PDF_LABELS: 'pdfLabels',
  PRODUCT_SKU_VIEW_LABELS: 'productSkuViewLabels',
  PROOF_OF_DELIVERY_LABELS: 'ProofOfDeliveryLabels',
  PROVISIONS_LABELS: 'invoiceProvisionsLabels',
  RECEIVER_LOCATION_LABELS: 'receiverLocationLabels',
  REGISTER_FREIGHT_DETAILS_LABELS: 'registerFreightDetailsLabels',
  REGISTER_FREIGHT_INVOICES_LABELS: 'registerFreightInvoicesLabels',
  REGISTER_REJECTION_LABELS: 'registerRejectionLabels',
  REJECTION_REASON_CREATE_LABELS: 'rejectionReasonCreateLabels',
  REJECTION_REASON_CREATE_TAGS: 'rejectionReasonCreateTags',
  REJECTION_REASON_VIEW_LABELS: 'rejectionReasonViewLabels',
  REJECTION_REASON_VIEW_TAGS: 'rejectionReasonViewTags',
  REVERT_FAILED_STAMP_LABELS: 'revertFailedStampLabels',
  REVERT_SHIPMENT_LABELS: 'revertShipmentLabels',
  REVIEW_CUSTOMER_BILLING: 'reviewCustomerBillingLabels',
  REVIEW_PAYMENT_SUPPLIER: 'reviewPaymentSupplierLabels',
  ROADNET_SERVICE_LABELS: 'roadnetServiceLabels',
  ROLE_CREATE_LABELS: 'roleCreateLabels',
  ROLE_VIEW_LABELS: 'roleViewLabels',
  SALES_SHEET_LABELS: 'salesSheetLabels',
  SEARCH_FILTER_LABELS: 'searchFilterLabels',
  SELECT_SEARCH: 'selectSearch',
  SERVICE_TYPE_CREATE_LABELS: 'serviceTypeCreateLabels',
  SERVICE_TYPE_CREATE_MESSAGES: 'serviceTypeCreateMessages',
  SERVICE_TYPE_CREATE_TAGS: 'serviceTypeCreateTags',
  SERVICE_TYPE_VIEW_LABELS: 'serviceTypeLabels',
  SERVICE_TYPE_VIEW_TAGS: 'serviceTypeViewTags',
  SHIPMENT_ASSIGNMENT_LABELS: 'shipmentAssignmentLabels',
  SHIPMENT_CREATION_LABELS: 'shipmentCreationLabels',
  SHIPMENT_CUSTOMS_REGIMEN_LABELS: 'shipmentCustomRegimenLabels',
  SHIPMENT_DETAIL_INFO_LABELS: 'shipmentDetailInfoLabels',
  SHIPMENT_DETAIL_INFO_STATUS: 'shipmentDetailInfoStatus',
  SHIPMENT_GRID: 'shipmentGridLabels',
  SHIPMENT_INFO_LABELS: 'shipmentInfoLabels',
  SHIPMENT_INFO_STATUS: 'shipmentInfoStatus',
  SHIPMENT_PROPERTIES_LABELS: 'shipmentPropertiesLabels',
  SHIPMENT_REQUEST_STATUS_LABELS: 'shipmentRequestStatusLabels',
  SHIPMENT_STATUS_CATALOG: 'shipmentStatusCatalog',
  SHIPMENT_STATUS_LABELS: 'shipmentStatusLabels',
  SHIPMENT_TEMPERATURE_LABELS: 'shipmentTemperatureLabels',
  SHIPMENT_TRIP_TYPES: 'shipmentTripTypes',
  SHIPMENT_VIEW: 'shipmentViewLabels',
  SPECIFIC_DELIVERY_SUMMARY_LABELS: 'specificDeliverySummaryLabels',
  STATUS_CHANGES: 'statusChangesLabels',
  STOP_LIST_TRANSLATED: 'stopStatusCatalogTranslated',
  STOP_LIST: 'stopListLabels',
  STOP_STATUS: 'stopStatusCatalog',
  SUPPLIER_CREATE_LABELS: 'createSupplierView',
  SUPPLIER_VIEW_LABELS: 'supplierCatalogView',
  TOASTR_ALERTS_SERVICE_LABELS: 'toastrAlertsServiceLabels',
  TRACKING_LABELS: 'trackingLabels',
  TRAILER_CREATE_LABELS: 'trailerCreateLabels',
  TRAILER_CREATE_TAGS: 'trailerCreateTags',
  TRAILER_VIEW_LABELS: 'trailerViewLabels',
  TRAILER_VIEW_TAGS: 'trailerViewTags',
  UNEXECUTED_LOAD: 'unexecutedLoadLabels',
  USER_CREATE_LABELS: 'userCreateLabels',
  USER_VIEW_LABELS: 'userViewLabels',
  VALIDATE_EVIDENCE_PDF_LABELS: 'ValidateEvidencePdfLabels',
  VEHICLE_ACCESS_CONTROL: 'vehicleAccessControlText',
  VEHICLE_CONTROL_TAGS: 'vehicleControlTags',
  VEHICLE_CREATE_LABELS: 'vehicleCreateLabels',
  VEHICLE_TYPE_CREATE: 'vehicleTypeCreateLabels',
  VEHICLE_TYPE_VIEW: 'vehicleTypeViewLabels',
  VEHICLE_VIEW_LABELS: 'vehicleViewLabels',
  WAREHOUSE_CREATE: 'warehouseCreateLocationLabels',
  WAREHOUSE_VIEW: 'warehouseViewLabels'
};
